import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './navbar.scss'
import { Button, OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faUserAlt, faSignOutAlt, faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useUserStore } from '../../store/store';
import Notifications from '../Notifications/Notifications'
import Popup from './Popup';
import { toast } from '../Toast';
import { AdminRoutes, AffiliateRoute } from '../../routes';

import { useLogoutUser } from '../../reactQuery/hooks/customMutationHook';
import { removeLoginToken } from '../../utils/storageUtils';

const Navbar = ({ open, setOpen }) => {
    const { t } = useTranslation(['sidebar'])
    const navigate = useNavigate()
    const isUserAffiliate = useUserStore((state) => state.isUserAffiliate)
    const [popoverOpen, setPopoverOpen] = useState(false);
    const notificationRef = useRef(null);

    const togglePopover = () => {
        setPopoverOpen(!popoverOpen);
    };

    const logoutUser = () => {
        removeLoginToken()
        localStorage.clear()
        toast(t('logoutSuccessToast'), 'success', 'logoutToast')
        navigate(isUserAffiliate ? AffiliateRoute.AffiliateSignIn : AdminRoutes.AdminSignin)
    }

    const { mutate: logout } = useLogoutUser({ onSuccess: () => logoutUser() })

    return (
        <>
            <div className='app-navbar p-1 d-flex justify-content-end align-items-center'>
            </div>
            <OverlayTrigger
                key="menu"
                placement='bottom'
                overlay={
                    <Tooltip id={`tooltip-profile`}>
                        {
                            !open ? (<strong>Open Menu</strong>) : (<strong>Close Menu</strong>)
                        }
                    </Tooltip>
                }
            >
                <Button
                    onClick={() => setOpen((current) => !current)}
                    className='btn menu-btn'
                >
                    {
                        !open ? (
                            <FontAwesomeIcon icon={faBars} className='me-1' style={{ color: 'rgb(38,43,64)' }} />
                        ) : (
                            <FontAwesomeIcon icon={faXmark} className='me-1' style={{ color: 'rgb(38,43,64)' }} />
                        )
                    }
                </Button>
            </OverlayTrigger>
            <OverlayTrigger
                key="profile"
                placement='bottom'
                overlay={
                    <Tooltip id={`tooltip-profile`}>
                        <strong>Profile</strong>
                    </Tooltip>
                }
            >
                <Button
                    onClick={() => navigate(AdminRoutes.Profile)}
                    className='btn profile-btn'
                >
                    <FontAwesomeIcon icon={faUserAlt} className='me-1' style={{ color: 'rgb(38,43,64)' }} />
                </Button>
            </OverlayTrigger>
            {/* <OverlayTrigger
                key="notifications"
                placement='bottom'
                overlay={
                    <Tooltip id={`tooltip-notifications`}>
                        <strong>Notifications</strong>
                    </Tooltip>
                }
            >
                <Button
                    ref={notificationRef}
                    onClick={togglePopover}
                    className='btn notification-btn'
                >
                    <FontAwesomeIcon icon={faBell} className='me-1' style={{ color: 'rgb(38,43,64)' }} />
                </Button>
            </OverlayTrigger> */}
            <Popup
                isOpen={popoverOpen}
                onClose={() => setPopoverOpen(false)}
                title="Notifications"
                targetElement={notificationRef.current}
            >
                <Notifications />
            </Popup>
            <OverlayTrigger
                key="logout"
                placement='bottom'
                overlay={
                    <Tooltip id={`tooltip-logout`}>
                        <strong>Logout</strong>
                    </Tooltip>
                }
            >
                <Button
                    onClick={() => logout()}
                    className='btn navbar-logout-btn'
                >
                    <FontAwesomeIcon icon={faSignOutAlt} className='me-1' style={{ color: 'rgb(38,43,64)' }} />
                </Button>
            </OverlayTrigger>
        </>
    )
}

export default Navbar