import React, { useRef } from 'react'
import { Overlay, Tooltip, } from '@themesberg/react-bootstrap';
import NotificationList from './NotificationList';

const Notifications = () => {

    return (
        <>
            <div>
                <NotificationList />
            </div>
        </>
    );
}

export default Notifications