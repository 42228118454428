import React, { useState } from "react"
import { Card, ListGroup, Button, InputGroup, Form, FormControl, OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap'
import './notifications.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faTrash, faCheckCircle } from "@fortawesome/free-solid-svg-icons"

const notificationsData = [
  {
    id: "notif-001",
    title: "Tournament Launched",
    message: "The High Roller Tournament is now live! Buy-in: $500, Prize Pool: $50,000.",
    type: "tournament",
    subtype: "launch",
    data: {
      tournamentName: "High Roller Tournament",
      buyIn: 500,
      prizePool: 50000,
      startDate: "2025-02-03T12:00:00Z",
      endDate: "2025-02-05T12:00:00Z",
      rules: "VIP members only"
    },
    link: "admin/tournaments/123",
    image: "tournament_banner.jpg",
    senderType: "admin",
    senderId: "admin-123",
    recipientId: "employee-456",
    timestamp: "2025-02-03T08:00:00Z",
    priority: "high",
    status: "unread"
  },
  {
    id: "notif-002",
    title: "Tournament Registration Open",
    message: "Registration for the Weekly Showdown is now open. Secure your spot now!",
    type: "tournament",
    subtype: "registration",
    data: {
      tournamentName: "Weekly Showdown",
      buyIn: 100,
      prizePool: 10000,
      startDate: "2025-02-02T15:00:00Z",
      endDate: "2025-02-02T15:00:00Z",
      rules: "Open to all members"
    },
    link: "admin/tournaments/124",
    image: "weekly_showdown_banner.jpg",
    senderType: "admin",
    senderId: "admin-456",
    recipientId: "employee-789",
    timestamp: "2025-02-02T09:30:00Z",
    priority: "medium",
    status: "unread"
  },
  {
    id: "notif-003",
    title: "Tournament Results Announced",
    message: "The results for the Grand Championship are out. Check the leaderboard now!",
    type: "tournament",
    subtype: "results",
    data: {
      tournamentName: "Grand Championship",
      buyIn: 1000,
      prizePool: 100000,
      startDate: "2025-01-25T18:00:00Z",
      endDate: "2025-01-30T18:00:00Z",
      rules: "Invite-only"
    },
    link: "admin/tournaments/125",
    image: "grand_championship_banner.jpg",
    senderType: "admin",
    senderId: "admin-789",
    recipientId: "employee-321",
    timestamp: "2025-02-01T14:00:00Z",
    priority: "high",
    status: "read"
  },
  {
    id: "notif-004",
    title: "New Policy Update",
    message: "A new workplace policy has been updated. Please review the details.",
    type: "policy",
    subtype: "update",
    data: {
      documentTitle: "Workplace Conduct Policy",
      effectiveDate: "2024-02-15",
      summary: "Updated guidelines on remote work and leave policies."
    },
    link: "admin/policies/456",
    image: "policy_update.jpg",
    senderType: "admin",
    senderId: "admin-222",
    recipientId: "employee-456",
    timestamp: "2024-02-10T08:00:00Z",
    priority: "medium",
    status: "unread"
  },
  {
    id: "notif-005",
    title: "System Maintenance Scheduled",
    message: "System maintenance is scheduled for this Sunday from 2 AM to 6 AM UTC.",
    type: "system",
    subtype: "maintenance",
    data: {
      startTime: "2024-02-18T02:00:00Z",
      endTime: "2024-02-18T06:00:00Z",
      impact: "Some services may be temporarily unavailable."
    },
    link: "admin/system-maintenance",
    image: "system_maintenance.jpg",
    senderType: "system",
    senderId: "system-001",
    recipientId: "all",
    timestamp: "2024-02-14T12:00:00Z",
    priority: "high",
    status: "unread"
  },
  {
    id: "notif-006",
    title: "Employee Recognition",
    message: "Congratulations to John Doe for being awarded Employee of the Month!",
    type: "announcement",
    subtype: "recognition",
    data: {
      employeeName: "John Doe",
      awardMonth: "February 2024",
      reason: "Outstanding customer service and dedication."
    },
    link: "admin/announcements/789",
    image: "employee_recognition.jpg",
    senderType: "hr",
    senderId: "hr-100",
    recipientId: "all",
    timestamp: "2024-02-12T10:00:00Z",
    priority: "low",
    status: "read"
  },
  {
    id: "notif-007",
    title: "New Message from HR",
    message: "Your annual performance review has been scheduled. Check your email for details.",
    type: "message",
    subtype: "hr",
    data: {
      reviewDate: "2024-03-01",
      manager: "Sarah Johnson",
      location: "Conference Room 2"
    },
    link: "admin/hr-reviews",
    image: "hr_review.jpg",
    senderType: "hr",
    senderId: "hr-200",
    recipientId: "employee-456",
    timestamp: "2024-02-15T15:30:00Z",
    priority: "high",
    status: "unread"
  },
  {
    id: "notif-008",
    title: "Weekly Newsletter",
    message: "Catch up on the latest company news and updates in this week’s newsletter.",
    type: "newsletter",
    subtype: "weekly",
    data: {
      edition: "Week 7 - 2024",
      highlights: ["New product launch", "Upcoming company event", "Employee spotlights"]
    },
    link: "admin/newsletters/007",
    image: "newsletter.jpg",
    senderType: "admin",
    senderId: "admin-300",
    recipientId: "all",
    timestamp: "2024-02-16T09:00:00Z",
    priority: "low",
    status: "unread"
  },
  {
    id: "notif-009",
    title: "Security Alert",
    message: "Unusual login attempt detected on your account. Please review immediately.",
    type: "security",
    subtype: "alert",
    data: {
      attemptedLocation: "New York, USA",
      attemptedTime: "2024-02-17T02:45:00Z",
      actionRequired: "Reset your password if this wasn't you."
    },
    link: "admin/security-alerts",
    image: "security_alert.jpg",
    senderType: "system",
    senderId: "system-002",
    recipientId: "employee-456",
    timestamp: "2024-02-17T03:00:00Z",
    priority: "high",
    status: "unread"
  }
]

const groupNotificationsByDate = (notifications) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset to start of today

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1); // Move to yesterday

  const grouped = {
    today: [],
    yesterday: [],
    older: []
  };

  notifications.forEach((notif) => {
    const notifDate = new Date(notif.timestamp);
    notifDate.setHours(0, 0, 0, 0); // Reset to start of notification's day

    if (notifDate.getTime() === today.getTime()) {
      grouped.today.push(notif);
    } else if (notifDate.getTime() === yesterday.getTime()) {
      grouped.yesterday.push(notif);
    } else {
      grouped.older.push(notif);
    }
  });

  return grouped;
};

const getTimeDifference = (timestamp) => {
  const now = new Date();
  const past = new Date(timestamp);
  const diffMs = now - past; // Difference in milliseconds

  const seconds = Math.floor(diffMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
  if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  return "Just now";
};

const NotificationPanel = () => {
  const [notifications, setNotifications] = useState(notificationsData);
  const [search, setSearch] = useState("");
  const [unreadOnly, setUnreadOnly] = useState(false);

  // Filter Notifications
  const filteredNotifications = notifications.filter(
    (notif) =>
      (unreadOnly ? notif.status === "unread" : true) &&
      notif.message.toLowerCase().includes(search.toLowerCase())
  );

  // Mark as Read
  const markAsRead = (id) => {
    setNotifications(notifications.map(n =>
      n.id === id ? { ...n, status: "read" } : n
    ));
  };

  // Delete Notification
  const deleteNotification = (id) => {
    setNotifications(notifications.filter(n => n.id !== id));
  };

  const groupedNotifications = groupNotificationsByDate(filteredNotifications);

  return (
    <Card className="notifications-list-container mt-3 shadow-sm" style={{ maxWidth: "500px", margin: "auto" }}>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <InputGroup className="w-75 me-3">
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <FormControl
            placeholder="Search Notifications"
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputGroup>
        <Form.Check
          className='unread-check'
          type="switch"
          id="unread-switch"
          label="Unread Only"
          checked={unreadOnly}
          onChange={() => setUnreadOnly(!unreadOnly)}
        />
      </Card.Header>

      <Card.Body>
        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
          {Object.keys(groupedNotifications).map((group) => (
            groupedNotifications[group].length > 0 && (
              <div key={group}>
                <h6 className="fw-bold">
                  {group === "today" ? "Today" : group === "yesterday" ? "Yesterday" : "Older"}
                </h6>
                <ListGroup variant="flush">
                  {groupedNotifications[group].map((notif) => (
                    <ListGroup.Item
                      key={notif.id}
                      className="d-flex align-items-center"
                      style={{ backgroundColor: notif.status === "read" ? "#f8f9fa" : "#ffffff" }}
                    >
                      <div className="flex-grow-1">
                        <strong>{notif.title}</strong>
                        <span className="time-text text-muted ms-2">{getTimeDifference(notif.timestamp)}</span>
                        <p className="notification-message mb-0 text-muted">{notif.message}</p>
                      </div>
                      <div className="d-flex flex-column align-items-end">
                        {notif.status === "unread" && (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-valid-from">
                                Mark as read
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="primary"
                              size="sm"
                              className="mb-1"
                              onClick={() => markAsRead(notif.id)}
                            >
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </Button>
                          </OverlayTrigger>
                        )}
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-valid-from">
                              Delete
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => deleteNotification(notif.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            )
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default NotificationPanel;
